import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { BsCheck2Circle } from "react-icons/bs"
import { ImSpinner2 } from "react-icons/im"
import { USER_SIGN_UP } from '../Graphql/Mutations'
import { useMutation } from '@apollo/client'
import sideImage from '../Images/Homeprint-login.jpeg'
import handle_errors from '../Helpers/auth_errors'
import { Link } from 'react-router-dom'
import PhoneInput from '../Components/PhoneInput'

function SignUp() {
    
    const [ userRegistered, setUserRegistered ] = useState( {} )
    const [ error, setError ] = useState(false)
    const [ userError, setUserError ] = useState(false)

    const formik = useFormik({
        initialValues:{
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            password: '',
            passwordConfirmation: '',
            isAgent: false,           
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            emailAddress: Yup.string().required('Required'),
            password: Yup.string().required('Required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Must contain at least 8 characters, an uppercase, a lowercase, a number and one of !@#$%^&*"),
            passwordConfirmation: Yup.string().required('Required')
            .test('passwords-match', 'Passwords must match', function(value) {
                return this.parent.password === value;
            }),
            phoneNumber: Yup.string().matches(/^\(\d{3}\) \d{3} \d{4}$/,'Incomplete phone number').required('Required')          
        }),
        onSubmit: values => {
            userSignUp({
                variables: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    agent: values.isAgent,
                    email: values.emailAddress,
                    phoneNumber: values.phoneNumber,
                    password: values.password,
                    passwordConfirmation: values.passwordConfirmation,
                    confirmSuccessUrl: 'http://localhost:3000/confirm',
                },
                onCompleted: (data) => {
                    setUserRegistered({
                        ...data.userRegister.authenticatable
                    })                
                },
                onError: (graphQLError) => {
                    console.error('error at signup: ', graphQLError)
                    if(graphQLError?.message?.toLowerCase() ==='account already exists, please login'){
                        setUserError('this account already exists, please go to login by clicking the link below.')
                        
                    } else if(graphQLError?.message?.toLowerCase() ==='account already exists, please verify then login'){
                        setUserError("It looks like you have already registered. Please check your email and follow the steps there to verify your account and log in. Don't forget to check your junk/spam folders.")
                        
                    } else if(graphQLError?.message?.toLowerCase() ==='email/phone number is already in use by someone else'){
                        setUserError(graphQLError?.message?.toLowerCase()+'.')
                        
                    } else {
                        setError({
                            ...graphQLError
                        })
                    }
                }
            })
        }
    })

    // useMutation hook
    const [userSignUp, { loading }] = useMutation(USER_SIGN_UP)

    
    return (
        <>
            {/* wrapper */}
            <div className="font-int flex  h-screen flex-col bg-white lg:relative">
                {/* container of body and image */}
                <div className="flex max-w-full flex-grow flex-col md:flex-row">
                    
                    {/* container for vertical alignment and large screens in 2 */}
                    <div className={`md:w-1/2 md:self-center ${ (error || Object.keys(userRegistered).length > 0 ) && 'h-full'}`}>
                        {/* container for horizontal alignment of text elements */}
                        
                        { error 
                            ? <div className="h-full mx-auto flex flex-col justify-center max-w-md px-3 py-12 sm:pt-12 sm:pb-12 md:max-lg:max-w-xs lg:py-16 xl:max-w-md">
                                {/* title */}
                                
                                <h1 className="mb-8 text-5xl font-bold tracking-tight text-gray-900 md:max-lg:text-4xl xl:tracking-tight">
                                    Something went wrong
                                </h1>
                                <div className="text-xl font-normal text-[#667085]">
                                    <p> Sorry, we couldn't register your account. Please try again later. </p>
                                    <p className='mt-4 text-sm font-normal text-[#667085]'> Go back to <a 
                                        href='/signup'
                                        className="text-[#6941C6] font-medium 2xl:text-base"
                                    > Sign up </a></p>
                                </div>
                            </div>
                            : (Object.keys(userRegistered).length > 0 
                                ? <div className="h-full mx-auto flex flex-col justify-center max-w-md px-3 py-12 sm:pt-12 sm:pb-12 md:max-lg:max-w-xs lg:py-16 xl:max-w-md">
                                    
                                    {/* title */}
                                    <h1 className="mb-12 text-5xl font-bold tracking-tight text-gray-900 md:max-lg:text-4xl xl:tracking-tight">
                                        Check your email
                                    </h1>
                                    <div className="text-xl font-normal text-[#667085]">
                                        <p className='mb-8'>{`Thanks for registering, ${userRegistered.firstName}!`} </p>
                                        <p className='mt-4 text-base'> We've sent an email with further instructions. Don't forget to also check your spam and junk folders if you don't see it in your inbox.</p>
                                        <p className='mt-4 text-base'> Once you have completed the steps outlined in the email, feel free to come back and sign in!  </p>
                                    </div>
                                    {/* <Link className='mt-12 btn-primary-lg' to={"/login"}> Sign in </Link> */}
                                </div>
                                : 
                                <div className='mx-auto max-w-sm px-3 py-12 sm:pt-12 sm:pb-12 lg:py-16 2xl:max-w-lg items-center'>
                                    <div className="flex justify-center items-center mb-20">
                                        <div 
                                            className="self-center h-12 w-full bg-[url('/public/homeprint.png')] bg-center bg-contain bg-no-repeat">                                    
                                        </div>
                                    </div>
                                    <div className="mx-auto max-w-sm px-3 sm:pb-12 lg:pb-16 2xl:max-w-lg">
                                        
                                        {/* title */}
                                        <h1 className="mb-12 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:tracking-tight">
                                            Sign Up
                                        </h1>
                                        {/* LinkedIn, Google, and Facebook Icons */}
                                        <div>
                                            {/* Social signin go here */}
                                            {/* <GoogleSignIn/> */}
                                        </div>
                                        <form
                                            className="max-w-sm md:max-w-md 2xl:max-w-lg"
                                            onSubmit={formik.handleSubmit}
                                        >
                                            {/* name */}
                                            <div className="flex flex-col py-2 sm:flex-row sm:justify-between">
                                                <label
                                                    className='sm:w-[48%]'                                                
                                                >
                                                    <span className="mb-1 block text-sm font-medium text-[#344054] sm:hidden"> 
                                                        First Name <span className="text-red-900 sm:hidden"> * </span> 
                                                    </span>
                                                    <input
                                                        placeholder="First name"
                                                        type="text"
                                                        name="firstName"
                                                        id="firstName"
                                                        autoComplete="given-name"
                                                        className="w-full border-blue-gray-300 block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.firstName}
                                                    />
                                                    {formik.touched.firstName && formik.errors.firstName 
                                                        ? <div className='mt-1 text-xs text-red-800'> {formik.errors.firstName} </div>
                                                        :  null                                                         
                                                    }
                                                </label>
                                                <label
                                                    className="sm:w-[48%]"
                                                >
                                                    <span className="mt-5 mb-1 block text-sm font-medium text-[#344054] sm:hidden">
                                                        Last Name <span className="text-red-900 sm:hidden"> * </span> 
                                                    </span>
                                                    <input
                                                        placeholder="Last name"
                                                        type="text"
                                                        name="lastName"
                                                        id="lastName"
                                                        autoComplete="family-name"
                                                        className="w-full border-blue-gray-300 block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:mt-0 sm:text-sm 2xl:text-base"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.lastName}
                                                    />
                                                    {formik.touched.lastName && formik.errors.lastName 
                                                        ? <div className='mt-1 text-xs text-red-800'> {formik.errors.lastName} </div>
                                                        :  null                                                         
                                                    }
                                                </label>
                                            </div>
                                            {/* Real estate agent */}
                                            <label className='block my-2'>
                                                <span className='text-sm font-medium text-[#344054] mr-8 sm:mr-5'> I am a real estate agent </span>
                                                <input 
                                                    type='checkbox' 
                                                    name='isAgent' 
                                                    onChange={formik.handleChange} 
                                                    checked={formik.values.isAgent} 
                                                    className='h-6 w-6 shrink-0 border-blue-gray-300 rounded-md border border-solid'
                                                />
                                            </label>
                                            {/* email address */}
                                            <div className="mx-auto flex flex-col py-2">
                                                <label
                                                    htmlFor="emailAddress"
                                                    className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                                >
                                                    Email <span className="text-red-900"> * </span>
                                                </label>
                                                <input
                                                    placeholder='you@example.com'
                                                    type="text"
                                                    name="emailAddress"
                                                    id="emailAddress"
                                                    autoComplete="email"
                                                    className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1.5 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.emailAddress}
                                                />
                                                {formik.touched.emailAddress && formik.errors.emailAddress 
                                                    ? <div className='mt-1 text-xs text-red-800'> {formik.errors.emailAddress} </div>
                                                    :  null                                                         
                                                }
                                            </div>
                                            {/* phone number */}
                                            <div className="mx-auto flex flex-col py-2">
                                                <label
                                                    htmlFor="phoneNumber"
                                                    className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                                >
                                                    Mobile phone <span className="text-red-900"> * </span>
                                                </label>
                                                <PhoneInput
                                                    placeholder='(123) 456 7890'
                                                    type="tel"
                                                    name="phoneNumber"
                                                    id="phoneNumber"
                                                    autoComplete="tel"
                                                    className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1.5 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phoneNumber}
                                                />
                                                {formik.touched.phoneNumber && formik.errors.phoneNumber 
                                                    ? <div className='mt-1 text-xs text-red-800'> {formik.errors.phoneNumber} </div>
                                                    :  null                                                         
                                                }
                                            </div>
                                            {/* Password */}
                                            <div className="mx-auto flex flex-col py-2">
                                                <label
                                                    htmlFor="password"
                                                    className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                                >
                                                    Password <span className="text-red-900"> * </span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    autoComplete="new-password"
                                                    className="border-blue-gray-300 2xl:text-bases col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.password}
                                                />
                                                { formik.touched.password 
                                                    ? (formik.errors.password
                                                        ? <div className='mt-1 text-xs text-red-800'> {formik.errors.password} </div>
                                                        : <div className='mt-1 text-xs text-[#039855]'> Strong </div>
                                                    ) 
                                                    : null 
                                                }  
                                            </div>
                                            {/* Password Confirmation */}
                                            <div className="mx-auto flex flex-col py-2">
                                                <label
                                                    htmlFor="passwordConfirmation"
                                                    className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                                >
                                                    Confirm password <span className="text-red-900"> * </span>
                                                </label>
                                                <input
                                                    type="password"
                                                    name="passwordConfirmation"
                                                    id="passwordConfirmation"
                                                    autoComplete="new-password"
                                                    className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.passwordConfirmation}
                                                />
                                                { formik.touched.passwordConfirmation 
                                                    ? (formik.errors.passwordConfirmation
                                                        ? <div className='mt-1 text-xs text-red-800'> {formik.errors.passwordConfirmation} </div>
                                                        : < BsCheck2Circle className='mt-2 h-5 w-5 text-[#039855]' />
                                                    ) 
                                                    : null 
                                                }
                                            </div>

                                            {handle_errors(error)}
                                            {/* Button's container */}
                                            
                                            <div className="mx-auto mt-4 flex flex-col md:max-w-sm 2xl:max-w-lg">
                                                {/* 'Create account' button */}
                                                <button
                                                    type='submit'
                                                    disabled={loading}
                                                    className={`mb-3 btn-primary-lg 2xl:text-lg ${loading ? 'bg-[#ddebf9]' : '' }`}
                                                >
                                                    { loading 
                                                        ? <div className='mx-auto h-5 w-5 bg-[#deedf8] rounded-full flex items-center justify-center animate-spin'>
                                                            <ImSpinner2 className='h-5 w-5 text-[#3E4784]'/>
                                                        </div>
                                                        : 'Create account' }
                                                </button>
                                                {/* <button className="mb-3 btn-secondary-lg 2xl:text-lg">
                                                    Sign up with Google
                                                </button> */}
                                                {userError && <div className='text-red-500 first-letter:uppercase mb-2'> {userError} </div>}
                                                {/* login button redirects to Login component */}
                                                <p className={ loading ? "hidden" : "text-center"}>
                                                    Already have an account?
                                                    <a
                                                        className="ml-1 text-[#6941C6] hover:cursor-pointer"
                                                        href="/login"
                                                    >
                                                        Log in
                                                    </a>
                                                </p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/* photo */}
                    <div className="hidden md:flex md:w-1/2">
                        <img
                            className="object-cover md:object-right"
                            src={sideImage}
                            alt="House at dusk, with warm lighting seen inside"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp
