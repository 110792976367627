export default function handle_errors(error){
    // console.log(error)
    return error &&
        <div className="text-red-500">
            {
                error.graphQLErrors 
                ? (
                error.graphQLErrors[0]?.extensions?.hasOwnProperty('detailed_errors') 
                    ? error.graphQLErrors[0]?.extensions.detailed_errors?.map((message) => {
                    return <p className="sm:pt-5" key={message}>{message}</p>
                    })
                    : error?.graphQLErrors[0]?.message 
                ) 
                : (
                    error?.message 
                    ? error?.message 
                    : error[0]
                )
            }
        </div>
}
